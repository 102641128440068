import {
  IconDownload,
  IconFile,
  IconPhotoUp,
} from '@cooperativaobrera/react-icons/tabler-icons';
import {
  Button, Grid, Typography, alpha, 
} from '@mui/material';
import theme from 'theme';
import { downloadFileBlob } from 'utils/downloadFile';

const convertSizeToKB = (size) => {
  return (size / 1024).toFixed(2) ?? 'Sin datos';
};
  
export default function FileDisplayerPrevisualizacionComponent({
  file,
  isImage,
}) {
  const fileIcon = isImage ? <IconPhotoUp /> : <IconFile />;
  
  return (
    <Grid
      container
      alignItems="center"
      justifyItems="center"
      direction="row"
      columnGap="15px"
      sx={{
        padding: 1,
        marginTop: 1,
        borderRadius: 1,
        border: '0',
        bgcolor: alpha(theme.palette.primary.main, 0.05),
      }}
    >
      <Grid
        item
        xs={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          border: '0',
          borderRadius: 1,
          height: '45px',
          bgcolor: 'white',
        }}
      >
        {fileIcon}
      </Grid>
      <Grid item xs={7}>
        <Grid>
          <Typography
            variant="h6"
            maxWidth={600}
            sx={{ wordWrap: 'anywhere', color: 'text.primary' }}
          >
            {file?.name}
          </Typography>
        </Grid>
  
        <Grid>
          <Typography sx={{ color: 'text.helper' }}>
            {`${convertSizeToKB(file?.size)} kb`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={1.5}
        sm
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          onClick={() => {
            const fileBlob = new Blob([file], { type: file.type });
            downloadFileBlob(fileBlob, file.name);
          }}
          sx={{ color: 'secondary.main', p: '5px' }}
        >
          <IconDownload />
        </Button>
      </Grid>
    </Grid>
  );
}
