export default function downloadFile(res) {
  const { base64, mime, name } = res.data.data;
  const link = document.createElement('a');
  link.href = `data:${mime};base64,${base64}`;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadFileBlob(file, fileName) {
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(file);
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}
