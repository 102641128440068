export default function calcularLiquidacion({
  monto,
  cuotas,
  saldoAnterior,
  categoria,
  coeficientes,
}) {
  let gsAdm = 0;
  let interes = 0;
  let valorCuota = 0;
  let fondoGarantia = 0;
  let montoACobrar = 0;
  
  if (categoria === 'S') {
    const coef99 = Number(coeficientes.find((coef) => coef.tipo === '99')?.indice);

    const potencia = (1 + coef99 / 12) ** cuotas;
    const divisor = (potencia - 1) / ((coef99 / 12) * potencia);
    gsAdm = (monto / divisor) - (monto / cuotas);

    if (coef99) {
      interes = coef99 * 100; // El coheficiente de los superiores es anual
    }
  } else {
    if (cuotas <= 10) {
      const coef10 = Number(coeficientes.find((coef) => coef.tipo === '10')?.indice);
      if (coef10) interes = coef10;
    } else if (cuotas <= 12) {
      const coef12 = Number(coeficientes.find((coef) => coef.tipo === '12')?.indice);
      if (coef12) interes = coef12;
    } else if (cuotas <= 15) {
      const coef15 = Number(coeficientes.find((coef) => coef.tipo === '15')?.indice);
      if (coef15) interes = coef15;
    } else {
      const coef18 = Number(coeficientes.find((coef) => coef.tipo === '18')?.indice);
      if (coef18) interes = coef18;
    }
    gsAdm = (monto * interes) / 100;
  }

  const total = parseFloat(monto) + gsAdm * cuotas;
  valorCuota = cuotas > 0 ? total / cuotas : 0;

  fondoGarantia = (monto - saldoAnterior) * 0.01;
  if (fondoGarantia < 0) {
    fondoGarantia = 0;
  }

  montoACobrar = monto - fondoGarantia - saldoAnterior;

  return {
    monto,
    gsAdm: !Number.isNaN(Number(gsAdm)) ? gsAdm : 0,
    fondoGarantia,
    valorCuota: !Number.isNaN(Number(valorCuota)) ? valorCuota : 0,
    montoACobrar,
    interes: !Number.isNaN(Number(interes)) ? interes : 0,
  };
}
