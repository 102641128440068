import {
  Box, Typography, styled, Badge, Grid, 
} from '@mui/material';
import Card from 'components/card/CardComponent';
import UsuarioIcon from 'components/icons/Usuario';
import useEmpleado from 'hooks/useEmpleado';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconPencil } from '@cooperativaobrera/react-icons/tabler-icons';
import obtenerLeyendaEmpresa from 'utils/obtenerLeyendaEmpresa';
import obtenerLogoEmpresa from 'utils/obtenerLogoEmpresa';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { USUARIO_JUBILADO } from 'constants/tipoUsuario';
import useUserHasPermission from 'hooks/useUserHasPermission';
import { PERMISO_NOTIFICACIONES } from 'constants/permisos';
import EditarFotoModal from './EditarFotoModalComponent';
import NotificacionesButton from './NotificacionesButtonComponent';

const Image = styled('img')(({ theme }) => ({
  height: '64px',
  width: '64px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('md')]: {
    height: '42px',
    width: '42px',
  },
}));

const styles = {
  fotoContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    typography: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  nombreApellidoText: {
    textTransform: 'capitalize',
  },
  editarBoton: {
    backgroundColor: 'secondary.main',
    color: 'common.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'common.white',
    borderRadius: 50,
    height: { xs: 25, md: 30 },
    width: { xs: 25, md: 30 },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};

export default function UsuarioCard() {
  const { t } = useTranslation();
  const notificacionesHabilitadas = useUserHasPermission(PERMISO_NOTIFICACIONES);
  const {
    state: { empleado, usuario },
  } = useEmpleado();
  const inputRef = useRef(null);
  const [modalState, setModalState] = useState({
    state: false,
  });
  const isMobile = useMobileBreakpoint();

  const handleClickEditarFoto = () => {
    // open file input box on click of other element
    inputRef.current.click();
  };
  
  const nombreEmpresa = obtenerLeyendaEmpresa(Number(empleado.id_empresa));
  const LogoEmpresa = obtenerLogoEmpresa(Number(empleado.id_empresa));

  return (
    <>
      <EditarFotoModal
        modalState={modalState}
        setModalState={setModalState}
        inputRef={inputRef}
      />
      <Card container="true">
        <Grid>
          <Grid
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            container
          >
            <Grid xs={12} item>
              <Box gap={3} display="flex" alignItems="center">
                <Box sx={styles.fotoContainer}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={(
                      <Box
                        component="button"
                        sx={styles.editarBoton}
                        onClick={handleClickEditarFoto}
                      >
                        <IconPencil fontSize="small" />
                      </Box>
                    )}
                  >
                    <Typography
                      sx={styles.fotoContainer.typography}
                      variant="body2"
                    >
                      {usuario.foto ? (
                        <Image
                          alt={t('pages.inicio.foto_alt')}
                          src={usuario.foto}
                        />
                      ) : (
                        <UsuarioIcon />
                      )}
                    </Typography>
                  </Badge>
                </Box>
                <Box>
                  {empleado.tipoUsuario !== USUARIO_JUBILADO && (
                    <Box display="flex" alignItems="center" gap={0.5}>
                      <LogoEmpresa height={isMobile ? 18 : 20} width="auto" />
                      <Typography variant="body2">{nombreEmpresa}</Typography>
                    </Box>
                  )}
                  <Typography variant="h6" sx={styles.nombreApellidoText}>
                    {`${usuario.nombre.toLowerCase()} ${usuario.apellido.toLowerCase()}`}
                  </Typography>
                  {empleado.tipoUsuario !== USUARIO_JUBILADO && (
                    <Typography color="secondary">
                      {`${t('pages.inicio.legajo')} ${empleado.legajo}`}
                    </Typography>
                  )}
                </Box>
                {notificacionesHabilitadas && (
                  <Grid
                    item
                    marginLeft="auto"
                  >
                    <NotificacionesButton />
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
