import empleadosApi from 'apis/EmpleadosApi';

const URL_NOTIFICACIONES = '/comunicados';

export default function obtenerNotificacionesUsuario() {
  return empleadosApi.get(URL_NOTIFICACIONES);
}

export function obtenerNotificacion(id) {
  return empleadosApi.put(`${URL_NOTIFICACIONES}/${id}`);
}

export function eliminarNotificacion(id) {
  return empleadosApi.delete(`${URL_NOTIFICACIONES}/${id}`);
}
