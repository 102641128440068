export default function decodeBase64(base64) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new TextDecoder('utf-8').decode(bytes);
}

function getMimeTypeFromBase64WithoutPrefix(base64) {
  const signatures = {
    '/9j/': 'image/jpeg',
    iVBORw0KGgo: 'image/png',
    R0lGODdh: 'image/gif',
    UklGR: 'image/webp',
    AAAAF: 'video/mp4',
    JVBERi0x: 'application/pdf',
    UEsFB: 'application/zip',
    DRAFT: 'application/msword', // DOC
    UEsDBBQAAAAIAIY8:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
  };

  // Extraemos los primeros caracteres que identifican el tipo de archivo
  const base64Header = base64.substring(0, 12);

  const foundSignature = Object.entries(signatures)
    .find(([signature]) => base64Header.startsWith(signature));

  if (foundSignature) {
    return foundSignature[1];
  }

  return 'text/plain';
}

export async function base64AFile(base64String, filename) {
  const mimeType = getMimeTypeFromBase64WithoutPrefix(base64String);

  const byteCharacters = atob(base64String);

  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < base64String.length; i += 1) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  return new File([byteArray], filename, { type: mimeType });
}
