export const PERMISO_RECIBO_SUELDO = 'RECIACCE';
export const PERMISO_RESUMEN_CUENTA = 'RESUACCE';
export const PERMISO_RESERVA_SALON = 'RESERSAL';
export const PERMISO_BMJP = 'BMPJACCE';
export const PERMISO_VACACIONES = 'VACAACCE';
export const PERMISO_AULA_VIRTUAL = 'AULACCE';
export const PERMISO_CERTIFICADO_LABORAL = 'CERTLABO';
export const PERMISO_CHAT_BOT = 'CHATBOT';
export const PERMISO_SOLICITUD_PRESTAMO = 'SOLIPRES';
export const PERMISO_NOTIFICACIONES = 'NOTIACCE';
