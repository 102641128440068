import Modal from 'components/modal/Modal2/ModalComponent';
import { Button, Typography } from '@mui/material';
import ModalHeader from 'components/modal/Modal2/ModalHeaderComponent';
import ModalActions from 'components/modal/Modal2/ModalActionsComponent';
import ModalContent from 'components/modal/Modal2/ModalContentComponent';
import { useTranslation } from 'react-i18next';
import useModal from 'hooks/useModal';
import DOMPurify from 'dompurify';

export default function TyCModal({ terminos, handleVolver }) {
  const { t } = useTranslation();
  const { modalState, closeModal } = useModal(true);

  return (
    <Modal
      sx={{
        maxWidth: {
          xs: '392px',
          sm: '600px',
          md: '752px',
        },
      }}
      open={modalState}
    >
      <ModalHeader>
        <Typography variant="h5" fontWeight={600}>
          Aceptar términos y condiciones
        </Typography>
      </ModalHeader>
      <ModalContent
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(terminos) }}
      />
      <ModalActions>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={closeModal}
          size="small"
        >
          {t('botones.aceptar')}
        </Button>
        <Button
          variant="outlined"
          color="tertiary"
          type="button"
          onClick={handleVolver}
          size="small"
        >
          {t('botones.volver')}
        </Button>
      </ModalActions>
    </Modal>
  );
}
