/* eslint-disable no-shadow */
import {
  Box, SvgIcon, createTheme, darken, lighten, 
} from '@mui/material';
import '@fontsource/inter';
import { IconX } from '@cooperativaobrera/react-icons/tabler-icons';

function IconChevronDown(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke="currentColor"
        fill="none"
        className="icon icon-tabler icon-tabler-chevron-down"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M6 9l6 6l6 -6" />
      </svg>
    </SvgIcon>
  );
}

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          ...(ownerState.size === 'smaller' && {
            height: theme.spacing(4),
            padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
            fontSize: '13px',
            '.MuiButton-iconSizeSmaller svg': {
              fontSize: 16,
            },
          }),
          ...(ownerState.size === 'small' && {
            height: theme.spacing(5),
            padding: `${theme.spacing(1)} ${theme.spacing(4)}`,

            // Agrego esta condicion para que se vea correctamente el boton de accion en el snackbar
            '&.MuiButton-colorInherit': {
              height: 'initial',
              padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
            },
          }),
          ...(ownerState.size === 'medium' && {
            height: theme.spacing(6),
            padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
          }),
          ...(ownerState.size === 'large' && {
            height: theme.spacing(7),
            padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState: { multiline, label, size }, theme }) => ({
          '& .MuiOutlinedInput-root': {
            height: multiline ? 'auto' : theme.spacing(6),

            ...(size === 'small' && {
              height: multiline ? 'auto' : theme.spacing(5),
            }),

            ...(size === 'smaller' && {
              height: multiline ? 'auto' : theme.spacing(4),
              ...theme.typography.body2,
              '& .MuiOutlinedInput-input': {
                padding: '0 7px',
              },
              '& .MuiOutlinedInput-input.MuiAutocomplete-input': {
                padding: 0,
              },

              '&.MuiInputBase-multiline': {
                padding: '7px 0',
              },
            }),

            '& fieldset': {
              top: !label && 0,
            },

            ...((size === 'small' || size === 'medium') && {
              ...theme.typography.body1,
            }),
          },
          '& .MuiInputLabel-root': {
            position: 'static',
            transform: 'none',
            marginBottom: theme.spacing(1),
            fontSize: theme.typography.body1.fontSize,
          },
          '& .MuiInputLabel-root.MuiInputLabel-sizeSmaller': {
            marginBottom: theme.spacing(0.5),
            fontSize: theme.typography.body2.fontSize,
          },
          '& .MuiFormHelperText-root': {
            fontSize: theme.typography.body2.fontSize,
          },
          ...(size === 'smaller' && {
            '& .MuiFormHelperText-root': {
              marginTop: 0,
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            },
          }),
          '& legend span': {
            display: 'none',
          },
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState: { severity }, theme }) => ({
          '& .MuiAlert-icon': {
            color: theme.palette.getContrastText(
              theme.palette[severity || 'error'].main,
            ),
          },
          backgroundColor: theme.palette[severity || 'error'].main,
          color: theme.palette.getContrastText(
            theme.palette[severity || 'error'].main,
          ),
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: '1.4rem',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: theme.spacing(6),

          '&:hover:not(.Mui-error):not(.Mui-disabled):not(.Mui-focused) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.palette.input?.main,
            },

          '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },

          '&.MuiInputBase-sizeSmall': {
            height: theme.spacing(5),
          },

          '&.MuiInputBase-sizeSmaller': {
            height: theme.spacing(4),
            ...theme.typography.body2,
          },
          '&.MuiInputBase-sizeSmaller .MuiSelect-select': {
            paddingLeft: 7,
          },

          '&.MuiInputBase-sizeSmall.MuiInputBase-sizeMedium': {
            ...theme.typography.body1,
          },
        }),
      },
      defaultProps: {
        IconComponent: ({ className }) => {
          const isOpen = className.includes('MuiSelect-iconOpen');
          return (
            <IconChevronDown
              fontSize="small"
              sx={{
                pointerEvents: 'none',
                position: 'absolute',
                right: '8px',
                transform: `rotate(${isOpen ? '180deg' : '0'})`,
                transition: 'transform 0.1s',
              }}
            />
          );
        },
        label: ' ',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState: { size }, theme }) => ({
          height: theme.spacing(6),
          paddingRight: '2px',

          '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },

          ...(size === 'smaller' && {
            height: theme.spacing(4),

            '& input': {
              paddingLeft: '7px',
            },
          }),

          ...(size === 'small' && {
            height: theme.spacing(5),
          }),

          ...theme.typography.body1,

          '& .MuiInputLabel-outlined': {
            position: 'static',
            transform: 'none',
            fontSize: theme.typography.body1.fontSize,
          },
          '& legend span': {
            display: 'none',
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: 'static',
          transform: 'none',
          marginBottom: theme.spacing(1),
          fontSize: `${theme.typography.body2.fontSize} !important`,
          '&.MuiInputLabel-sizeSmaller': {
            marginBottom: theme.spacing(0.5),
            fontSize: theme.typography.body2.fontSize,
          },
        }),
      },
    },
    MuiNativeSelect: {
      defaultProps: {
        IconComponent: (iconProps) => {
          return (
            <IconChevronDown
              fontSize="small"
              sx={{
                pointerEvents: 'none',
                position: 'absolute',
                right: '8px',
              }}
              {...iconProps}
            />
          );
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          width: 42,
          height: 26,
          padding: 0,
          marginRight: 5,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: ownerState.color,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor:
              theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            flexWrap: 'nowrap !important',
          },
        },
      },
      defaultProps: {
        clearIcon: (
          <Box
            sx={{
              height: 24,
              width: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconX fontSize="small" />
          </Box>
        ),
        popupIcon: (
          <Box
            sx={{
              height: 24,
              width: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconChevronDown fontSize="small" />
          </Box>
        ),
        renderTags: (tagValue) => (
          <Box
            /* onClick={() => {
              setOpen(true);
            }} */
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {tagValue.map((option, index) => (
                <span key={option.label}>
                  {index !== 0 ? `, ${option.label}` : `${option.label}`}
                </span>
              ))}
            </p>
          </Box>
        ),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#22457C',
    },
    secondary: {
      main: '#4082F6',
    },
    tertiary: {
      main: '#0B58A4',
    },
    accent: {
      light: lighten('#4082F6', 0.2),
      main: '#4082F6',
      dark: darken('#4082F6', 0.2),
      contrastText: '#FFF',
    },
    text: {
      primary: '#333951',
      secondary: '#3C3C3C',
      helper: '#676767',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#F57C00',
    },
    info: {
      main: '#0288D1',
    },
    success: {
      main: '#388E3C',
    },
    background: {
      default: '#FCFCFC',
    },
    input: {
      main: '#333951',
    },
    line: {
      main: '#CBCBCB',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Inter'].join(','),
    h1: {
      fontSize: '3.5rem',
      '@media (min-width:600px)': {
        fontSize: '4rem',
      },
      '@media (min-width:900px)': {
        fontSize: '5rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '6rem',
      },
    },
    h2: {
      fontSize: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
      '@media (min-width:900px)': {
        fontSize: '3.25rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '3.75rem',
      },
    },
    h3: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:900px)': {
        fontSize: '2.75rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '3rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.7rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1.9rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '2rem',
      },
    },
    h5: {
      fontSize: '1.25rem',
      '@media (min-width:600px)': {
        fontSize: '1.3rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1.4rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.1rem',
      },
    },
    subtitle1: {
      fontSize: '0.85rem',
      '@media (min-width:600px)': {
        fontSize: '0.85rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1rem',
      },
    },
    subtitle2: {
      fontSize: '0.85rem',
      '@media (min-width:600px)': {
        fontSize: '0.85rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1rem',
      },
    },
    body1: {
      fontSize: '0.85rem',
      '@media (min-width:600px)': {
        fontSize: '0.85rem',
      },
      '@media (min-width:900px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1rem',
      },
    },
    body2: {
      fontSize: '0.85rem',
      '@media (min-width:600px)': {
        fontSize: '0.85rem',
      },
      '@media (min-width:900px)': {
        fontSize: '0.85rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '0.85rem',
      },
    },
    helper: {
      fontSize: '0.8rem',
      '@media (min-width:600px)': {
        fontSize: '0.8rem',
      },
      '@media (min-width:900px)': {
        fontSize: '0.85rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '0.85rem',
      },
    },
  },
});
export default theme;
