import Card from 'components/card/CardComponent';
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import FormikTextField from 'components/inputs/FormikTextFieldComponent';
import FormikSelect from 'components/selects/FormikSelectComponent';
import { useTranslation } from 'react-i18next';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import onlyNumbersValidate from 'utils/onlyNumbersValidate';
import LiquidacionDetallada from './LiquidacionDetallada';

const styles = {
  container: {
    display: 'flex',
    gap: {
      xs: '25px',
      md: '50px',
    },
    flexWrap: 'wrap',
  },
};

const formatNumber = (number) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export default function SolicitudPrestamoCard({ form }) {
  const { t } = useTranslation();
  const isMobile = useMobileBreakpoint();

  return (
    <Card sx={styles.container}>
      <form style={{ width: '100%' }} onSubmit={form.handleSubmit}>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid xs={12} item>
            <Typography fontWeight={600}>
              {t('pages.solicitudes.pedido_prestamo.solicitud_ayuda')}
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <FormikTextField
              id="monto"
              label={t('pages.solicitudes.pedido_prestamo.monto_input')}
              value={formatNumber(form.values.monto)}
              // Limita el maximo de caracteres a la cantidad del monto mámixo a otorgar
              onChange={(e) => {
                const valueWithoutFormat = e.target.value.length
                  <= form.values.datosEmpleado.maximoOtorgar.length
                  ? e.target.value.replace(/\./g, '')
                  : form.values.monto.replace(/\./g, '');
                form.setFieldValue('monto', valueWithoutFormat);
              }}
              validate={onlyNumbersValidate}
              formik={form}
              fullWidth
            />
          </Grid>
          <Grid xs={6} item>
            <FormikSelect
              id="cuotas"
              label={t('pages.solicitudes.pedido_prestamo.cuotas_input')}
              formik={form}
              fullWidth
            >
              {form.values.selects.cuotas.map((cuota) => (
                <MenuItem key={cuota.value} value={cuota.value}>
                  {cuota.label}
                </MenuItem>
              ))}
            </FormikSelect>
          </Grid>
          <Grid xs={6} item>
            <FormikSelect
              id="motivo"
              label={t('pages.solicitudes.pedido_prestamo.motivo_input')}
              formik={form}
              fullWidth
            >
              {form.values.selects.motivos.map((motivo) => (
                <MenuItem key={motivo.value} value={motivo.value}>
                  {motivo.label}
                </MenuItem>
              ))}
            </FormikSelect>
          </Grid>
          <Grid xs={6} item>
            <FormikTextField
              id="detalle_motivo"
              label={t(
                'pages.solicitudes.pedido_prestamo.detalle_motivo_input',
              )}
              formik={form}
            />
          </Grid>
          <Grid xs={12} item>
            <Divider />
          </Grid>
          <Grid xs={12} item>
            <Typography>
              {t('pages.solicitudes.pedido_prestamo.datos_beneficiario')}
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <FormikSelect
              id="tipo_documento"
              label={t(
                'pages.solicitudes.pedido_prestamo.tipo_documento_input',
              )}
              formik={form}
              fullWidth
            >
              {form.values.selects.tiposDocumentos.map((tipoDocumento) => (
                <MenuItem key={tipoDocumento.value} value={tipoDocumento.value}>
                  {tipoDocumento.label}
                </MenuItem>
              ))}
            </FormikSelect>
          </Grid>
          <Grid xs={8} item>
            <FormikTextField
              id="nro_documento"
              label={t('pages.solicitudes.pedido_prestamo.documento_input')}
              formik={form}
              validate={onlyNumbersValidate}
            />
          </Grid>
          <Grid xs={6} item>
            <FormikTextField
              id="nombre"
              label={t('pages.solicitudes.pedido_prestamo.nombre_input')}
              formik={form}
            />
          </Grid>
          <Grid xs={6} item>
            <FormikTextField
              id="apellido"
              label={t('pages.solicitudes.pedido_prestamo.apellido_input')}
              formik={form}
            />
          </Grid>
          <Grid xs={12} item>
            <FormikTextField
              id="domicilio"
              label={t('pages.solicitudes.pedido_prestamo.domicilio_input')}
              formik={form}
            />
          </Grid>
          {isMobile && (
            <Grid xs={12} item>
              <LiquidacionDetallada form={form} />
            </Grid>
          )}
          <Grid xs={12} item>
            <Button variant="contained" type="submit" fullWidth>
              {t(
                'pages.solicitudes.pedido_prestamo.botones.solicitar_ayuda',
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}
