import { ButtonBase, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useEmpleado from 'hooks/useEmpleado';
import { IconChevronLeft } from '@cooperativaobrera/react-icons/tabler-icons';
import convertYearMonthToFormattedDate from 'utils/convertYearMonthToFormattedDate';
import { getCuentaPeriodos, getCuentaResumenCuentaPdf } from 'services/Ares';
import useFirebase from 'hooks/useFirebase';
import {
  TARJETA_EMPLEADO,
  TARJETA_JUBILADO,
  TARJETA_SERVICIO,
} from 'constants/tipoTarjeta';
import ReciboSueldoPDF from './components/ResumenCuentaPDFComponent';
import SeleccionarReciboSueldoCard from './components/SeleccionarResumenCuentaCardComponent';

export default function ResumenCuentaPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {
      empleado: { tarjeta },
    },
  } = useEmpleado();
  const [periodos, setPeriodos] = useState([]);
  const [resumenCuentaSeleccionado, setResumenCuentaSeleccionado] = useState({
    id: '',
    file: '',
  });
  const {
    state: { firebaseInstance },
  } = useFirebase();

  const obtenerResumenCuentaPDF = async (periodo) => {
    return getCuentaResumenCuentaPdf(periodo).then((res) => {
      setResumenCuentaSeleccionado({
        id: periodo,
        file: res.data.data,
      });

      return res;
    });
  };

  const descargarRecibo = (file, id) => {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${file}`;
    link.setAttribute('download', convertYearMonthToFormattedDate(id));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClickDescargarResumenCuenta = (id) => {
    if (id === resumenCuentaSeleccionado.id) {
      descargarRecibo(
        resumenCuentaSeleccionado.file,
        resumenCuentaSeleccionado.id,
      );
    } else {
      obtenerResumenCuentaPDF(id).then((res) => {
        descargarRecibo(res.data.data.recibo, id);
      });
    }
  };

  useEffect(() => {
    getCuentaPeriodos().then((res) => {
      setPeriodos(res.data.data);
      // Preselecciono el primer recibo
      const ultimoPeriodo = res.data.data[0].periodo;
      obtenerResumenCuentaPDF(ultimoPeriodo);
    });

    firebaseInstance?.logEvent('visualizo-resumen-ares');
  }, []);

  return (
    <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} item>
        <Typography fontWeight={600}>
          {tarjeta === TARJETA_EMPLEADO
            && t('pages.tarjeta_empleado.titulo_empleado')}
          {tarjeta === TARJETA_SERVICIO
            && t('pages.tarjeta_empleado.titulo_servicio')}
          {tarjeta === TARJETA_JUBILADO
            && t('pages.tarjeta_empleado.titulo_jubilado')}
        </Typography>
      </Grid>

      <Grid xs={12} item>
        <ButtonBase onClick={() => navigate('/tarjeta-empleado')} disableRipple>
          <IconChevronLeft />
          <Typography>{t('pages.tarjeta_empleado.resumen_cuenta')}</Typography>
        </ButtonBase>
      </Grid>
      <Grid xs={12} lg={4} item>
        <SeleccionarReciboSueldoCard
          {...{
            periodos,
            resumenCuentaSeleccionado,
            handleClickResumenCuenta: obtenerResumenCuentaPDF,
            handleClickDescargarResumenCuenta,
          }}
        />
      </Grid>
      <Grid xs={12} lg={8} item>
        {resumenCuentaSeleccionado.file && (
          <ReciboSueldoPDF pdf={resumenCuentaSeleccionado.file} />
        )}
      </Grid>
    </Grid>
  );
}
