import useAlerts from 'hooks/useAlerts';
import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal2/ModalComponent';
import ModalContent from 'components/modal/Modal2/ModalContentComponent';
import ModalActions from 'components/modal/Modal2/ModalActionsComponent';
import { base64AFile } from 'utils/base64';
import { obtenerNotificacion } from 'services/NotificacionesService';
import useNotificaciones from 'hooks/useNotificaciones';
import TemplateAHtmlComponent from './TemplateAHTMLComponent';
import FileDisplayerPrevisualizacion from './FileDisplayerPrevisualizacionComponent';

export default function VerNotificacionModalComponent({ open, handleClose, idComunicado }) {
  const { openSnackbar, startLoading, stopLoading } = useAlerts();
  const [vistaPrevia, setVistaPrevia] = useState('');
  const [archivosAdjuntos, setArchivosAdjuntos] = useState([]);
  const { obtenerNotificaciones } = useNotificaciones();

  async function getVistaPrevia() {
    const loadingId = startLoading('Cargando notificacion');
    try {
      const res = await obtenerNotificacion(idComunicado);
      setVistaPrevia(res.data.data.html);

      const adjuntos = await Promise.all(
        res.data.data.adjuntos.map((adjunto, index) => base64AFile(
          adjunto,
          `archivo_adjunto_${index + 1}`,
        )),
      );
      setArchivosAdjuntos(adjuntos);
    } catch (error) {
      openSnackbar({
        message: 'Ocurrió un error inesperado en el servidor',
        severity: 'error',
      });
    } finally {
      obtenerNotificaciones();
      stopLoading(loadingId);
    }
  }

  useEffect(() => {
    getVistaPrevia();
  }, []);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      sx={{ 
        sm: { maxWidth: 'calc(100% - 30px)' },
        p: 0,
      }}
    >
      <ModalContent sx={{ borderRadius: '10px', margin: 0 }}>
        <TemplateAHtmlComponent template={vistaPrevia} />
        <Grid sx={{ mx: '20px' }}>
          {Array.isArray(archivosAdjuntos)
            && archivosAdjuntos?.length > 0
            && archivosAdjuntos?.map((archivoAdjunto) => (
              <FileDisplayerPrevisualizacion
                file={archivoAdjunto}
                isImage={false}
                key={archivoAdjunto.name}
              />
            ))}
        </Grid>
      </ModalContent>
      <ModalActions sx={{ padding: '16px' }}>
        <Button onClick={handleClose} variant="outlined">
          Cerrar
        </Button>
      </ModalActions>
    </Modal>
  );
}
