import {
  memo, useEffect, useRef, useState, 
} from 'react';
import useNotificaciones from 'hooks/useNotificaciones';
import {
  Typography,
  styled,
  Drawer as MuiDrawer,
  Box,
  createTheme,
} from '@mui/material';
import { IconChevronRight } from '@cooperativaobrera/react-icons/tabler-icons';
import NotificacionesList from './notificacionesList';

const theme = createTheme();

const drawerWidth = {
  open: 520,
  closed: 0,
};

const openedMixin = () => ({
  [theme.breakpoints.up('xs')]: {
    width: drawerWidth.open,
    maxWidth: '100%',
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = () => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.up('xs')]: {
    width: drawerWidth.closed,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }) => ({
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(60),
  },
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(),
    '& .MuiDrawer-paper': openedMixin(),
  }),
  ...(!open && {
    ...closedMixin(),
    '& .MuiDrawer-paper': closedMixin(),
  }),
  '&  .MuiDrawer-paper': {
    border: '1px solid #E8E7E7;',
  },
}));

function NotificacionesDrawer() {
  const whiteSpaceTimeoutRef = useRef();
  const [whiteSpace, setWhiteSpace] = useState('nowrap');
  const {
    state: { open, notificaciones },
    openNotificacionesDrawer,
    closeNotificacionesDrawer,
  } = useNotificaciones();

  useEffect(() => {
    clearTimeout(whiteSpaceTimeoutRef.current);

    if (open) {
      whiteSpaceTimeoutRef.current = setTimeout(() => {
        setWhiteSpace('break-spaces');
      }, theme.transitions.duration.leavingScreen);
    } else {
      setWhiteSpace('nowrap');
    }

    return () => {
      clearTimeout(whiteSpaceTimeoutRef.current);
    };
  }, [open]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        anchor="right"
        onClick={openNotificacionesDrawer}
        onMouseLeave={closeNotificacionesDrawer}
        PaperProps={{
          sx: {
            height: 'calc(100% - 30px)',
            top: 30,
          },
        }}
        variant="permanent"
        open={open}
      >
        <Box p={1.5} mt={3}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Typography
              variant="body1"
              fontWeight={600}
              data-is-visible={open}
              sx={{ whiteSpace }}
              gutterBottom
            >
              Notificaciones
            </Typography>
            <IconChevronRight
              sx={{ cursor: 'pointer' }}
              onClick={(event) => {
                event.stopPropagation();
                closeNotificacionesDrawer();
              }}
            />
          </Box>
          {(notificaciones.nuevas.length === 0 && notificaciones.leidas.length === 0) ? (
            <Typography m={2}>
              Sin notificaciones
            </Typography>
          ) : (
            <>
              <NotificacionesList listado={notificaciones.nuevas} nuevas />
              <NotificacionesList listado={notificaciones.leidas} />
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}

export default memo(NotificacionesDrawer);
