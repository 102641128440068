import { Divider, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import formatCurrency from 'utils/formatCurrency';
import { useTranslation } from 'react-i18next';
import Item from './Item';
import calcularLiquidacion from '../utils/calcularLiquidacion';

export default function LiquidacionDetallada({ form }) {
  const { t } = useTranslation();
  const liquidacionData = useMemo(() => {
    if (form.values.coeficientes.length) {
      return calcularLiquidacion({
        ...form.values,
        monto: form.values.monto > 0 ? Number(form.values.monto) : 0,
        cuotas: Number(form.values.cuotas),
        saldoAnterior: Number(form.values.saldoAnterior),
        categoria: form.values.datosEmpleado.categoria,
      });
    }

    return {
      monto: 0,
      gsAdm: 0,
      fondoGarantia: 0,
      valorCuota: 0,
      montoACobrar: 0,
    };
  }, [form.values]);

  return (
    <Stack gap={1} width="100%">
      <Typography fontWeight={600}>
        {t('pages.solicitudes.pedido_prestamo.liquidacion_detallada')}
      </Typography>
      <Stack gap={0.5}>
        <Item
          label={t('pages.solicitudes.pedido_prestamo.monto_solicitado')}
          value={formatCurrency(liquidacionData.monto)}
        />
        <Item
          label={form.values.datosEmpleado.categoria === 'S'
            ? `${t('pages.solicitudes.pedido_prestamo.tasa_servicio_anual')} (${liquidacionData.interes}%)`
            : `${t('pages.solicitudes.pedido_prestamo.tasa_servicio_mensual')} (${liquidacionData.interes}%)`}
          value={formatCurrency(liquidacionData.gsAdm)}
        />
        <Item
          label={t('pages.solicitudes.pedido_prestamo.fondo_garantia')}
          value={formatCurrency(liquidacionData.fondoGarantia)}
        />
        <Divider />
        <Item label={t('pages.solicitudes.pedido_prestamo.cantidad_cuotas')} value={form.values.cuotas} />
        <Item
          label={t('pages.solicitudes.pedido_prestamo.valor_cuota')}
          value={formatCurrency(liquidacionData.valorCuota)}
        />
        <Item
          label={t('pages.solicitudes.pedido_prestamo.saldo_prestamo_anterior')}
          value={formatCurrency(
            form.values.saldoAnterior > 0 ? form.values.saldoAnterior : 0,
          )}
        />
        <Item
          label={t('pages.solicitudes.pedido_prestamo.saldo_aem')}
          value={formatCurrency(
            form.values.saldoAEM > 0 ? form.values.saldoAEM : 0,
          )}
        />
        <Divider />
        <Item
          label={t('pages.solicitudes.pedido_prestamo.monto_cobrar')}
          value={formatCurrency(liquidacionData.montoACobrar)}
          fontWeight={600}
        />
      </Stack>
    </Stack>
  );
}
