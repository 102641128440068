import Card from 'components/card/CardComponent';
import {
  Grid,
  Stack, Typography, 
} from '@mui/material';
import ListItem2 from 'components/infoItem/ListItem2Component';
import formatCurrency from 'utils/formatCurrency';
import { useTranslation } from 'react-i18next';
import { CATEGORIA_BASE, CATEGORIA_JERA, CATEGORIA_SUPE } from '../constants';

const styles = {
  container: {
    display: 'flex',
    gap: {
      xs: '25px',
      md: '50px',
    },
    flexWrap: 'wrap',
  },
};

function getCategoria(categoria) {
  switch (categoria) {
    case CATEGORIA_BASE:
      return 'Base';
    case CATEGORIA_JERA:
      return 'Jerárquico';
    case CATEGORIA_SUPE:
      return 'Superior';
    default:
      return 'Categoria no conocida';
  }
}

// eslint-disable-next-line no-unused-vars
export default function DatosEmpleadoCard({
  datosEmpleado: {
    antiguedad,
    categoria,
    maximoCuotas,
    maximoOtorgar,
  }, 
}) {
  const { t } = useTranslation();

  return (
    <Card sx={styles.container}>
      <Stack gap={1} width="100%">
        <Typography fontWeight={600}>{t('pages.solicitudes.pedido_prestamo.datos_empleado')}</Typography>
        <Grid spacing={1} container>
          <Grid xs={3} item>
            <ListItem2
              label={t('pages.solicitudes.pedido_prestamo.antiguedad')}
              value={antiguedad}
              labelColor="secondary"
            />
          </Grid>
          <Grid xs={3} item>
            <ListItem2
              label={t('pages.solicitudes.pedido_prestamo.categoria')}
              value={getCategoria(categoria)}
              labelColor="secondary"
            />
          </Grid>
          <Grid xs={3} item>
            <ListItem2
              label={t('pages.solicitudes.pedido_prestamo.maximo_cuotas')}
              value={maximoCuotas}
              labelColor="secondary"
            />
          </Grid>
          <Grid xs={3} item>
            <ListItem2
              label={t('pages.solicitudes.pedido_prestamo.maximo_otorgar')}
              value={formatCurrency(Number(maximoOtorgar))}
              labelColor="secondary"
            />
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}
