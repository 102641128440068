import {
  IconBell,
  IconCake,
  IconConfetti,
  IconFileInvoice,
  IconHeartHandshake,
  IconSpeakerphone,
} from '@cooperativaobrera/react-icons/tabler-icons';

import { 
  TIPO_ANIVERSARIO_LOCAL, 
  TIPO_CUMPLEANIOS, 
  TIPO_ENTRE_NOSOTROS,
  TIPO_INSTITUCIONAL,
  TIPO_MISCELANEO,
} from 'constants/notificaciones';

const DEFAULT_HEIGHT = 18;
const DEFAULT_WIDTH = 18;
const DEFAULT_COLOR = 'accent';
const DEFAULT_STROKE = 1.5;

export default function IconoNotificacionesComponent({ tipo }) {
  switch (tipo) {
    case TIPO_INSTITUCIONAL:
      return (
        <IconFileInvoice
          color={DEFAULT_COLOR}
          width={DEFAULT_WIDTH}
          height={DEFAULT_HEIGHT}
        />
      );
    case TIPO_ENTRE_NOSOTROS:
      return (
        <IconHeartHandshake
          color={DEFAULT_COLOR}
          width={DEFAULT_WIDTH}
          height={DEFAULT_HEIGHT}
          sx={{ strokeWidth: DEFAULT_STROKE }}
        />
      );
    case TIPO_MISCELANEO:
      return (
        <IconSpeakerphone
          color={DEFAULT_COLOR}
          width={DEFAULT_WIDTH}
          height={DEFAULT_HEIGHT}
        />
      );
    case TIPO_CUMPLEANIOS:
      return (
        <IconCake
          color={DEFAULT_COLOR}
          width={DEFAULT_WIDTH}
          height={DEFAULT_HEIGHT}
        />
      );
    case TIPO_ANIVERSARIO_LOCAL:
      return (
        <IconConfetti
          color={DEFAULT_COLOR}
          width={DEFAULT_WIDTH}
          height={DEFAULT_HEIGHT}
        />
      );
    default:
      return (
        <IconBell
          color={DEFAULT_COLOR}
          width={DEFAULT_WIDTH}
          height={DEFAULT_HEIGHT}
          sx={{ strokeWidth: DEFAULT_STROKE }}
        />
      );
  }
}
