import { Box } from '@mui/material';
import DOMPurify from 'dompurify';
import decodeBase64 from 'utils/base64';

export default function TemplateAHtmlComponent({ template }) {
  const htmlDecodificado = decodeBase64(template);
  const htmlSanitizado = DOMPurify.sanitize(htmlDecodificado, {
    WHOLE_DOCUMENT: true,
    ALLOW_UNKNOWN_PROTOCOLS: true,
  });
    
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: htmlSanitizado }}
    />
  ); 
}
