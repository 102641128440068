import * as Yup from 'yup';

const solicitudPrestamoSchema = (documentoUsuario, maximoOtorgar) => Yup.object({
  monto: Yup.string().required('El campo es requerido'),
  cuotas: Yup.string().required('El campo es requerido'),
  motivo: Yup.string().required('El campo es requerido'),
  detalle_motivo: Yup.string().required('El campo es requerido'),

  tipo_documento: Yup.string(),
  nro_documento: Yup.string(),
  apellido: Yup.string(),
  nombre: Yup.string(),
  domicilio: Yup.string(),
}).test(
  'todos-o-ninguno',
  null,
  function validar(value) {
    const {
      tipo_documento, nro_documento, apellido, nombre, domicilio, monto,
    } = value;

    const anyPresent = tipo_documento || nro_documento || apellido || nombre || domicilio;
    const allPresent = tipo_documento && nro_documento && apellido && nombre && domicilio;

    if (anyPresent && !allPresent) {
      return this.createError({
        path: 'nro_documento',
        message: 'Si uno de los campos opcionales está presente, todos deben ser completados',
      });
    }

    if (Number(nro_documento) === Number(documentoUsuario)) {
      return this.createError({
        path: 'nro_documento',
        message: 'El beneficiario no puede ser el solicitante',
      });
    }

    if (Number(monto) > Number(maximoOtorgar)) {
      return this.createError({
        path: 'monto',
        message: 'Excede el monto máximo',
      });
    }

    return true;
  },
);

export default solicitudPrestamoSchema;
