import React, { memo } from 'react';
import {
  Badge, Box, Divider, Typography,
  useTheme,
} from '@mui/material';
import NotificacionComponent from './NotificacionComponent';

function NotificacionesList({ listado, nuevas }) {
  const theme = useTheme();

  const badgeStyle = () => ({
    '& .MuiBadge-badge': {
      borderRadius: '4px',
      top: 10,
      left: 0,
      height: 18,
      minWidth: 18,
      color: 'white',
      backgroundColor: nuevas 
        ? theme.palette.accent.main 
        : theme.palette.input.main,
    },
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="body2" fontWeight={600} gutterBottom>
          {nuevas ? 'Nuevas' : 'Todas'}
        </Typography>
        <Badge
          badgeContent={String(listado.length)}
          sx={badgeStyle(nuevas, theme)}
        />
      </Box>
      {listado.map((notificacion) => {
        return (
          <React.Fragment key={notificacion.id}>
            <NotificacionComponent
              key={notificacion.id}
              notificacion={notificacion}
            />
            <Divider sx={{ marginY: 1 }} />
          </React.Fragment>
        );
      })}
    </Box>
  );
}

export default memo(NotificacionesList);
