import { Box, Typography } from '@mui/material';

export default function Item({ label, value, fontWeight = 400 }) {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography fontWeight={fontWeight}>{label}</Typography>
      <Typography fontWeight={fontWeight}>{value}</Typography>
    </Box>
  );
}
