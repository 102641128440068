import useNotificaciones from 'hooks/useNotificaciones';
import { IconBell } from '@cooperativaobrera/react-icons/tabler-icons';
import { Badge } from '@mui/material';
import Card from 'components/card/CardComponent';

const styles = {
  iconContainer: {
    maxWidth: 24,
    maxHeight: 24,
    color: 'text.main',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '30px',
    },
    cursor: 'pointer',
  },
};

export default function NotificacionesButton() {
  const {
    state: {
      open,
      notificaciones: { nuevas },
    },
    openNotificacionesDrawer,
    closeNotificacionesDrawer,
  } = useNotificaciones();

  return (
    <Card
      sx={{
        width: 64,
        height: 64,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Badge
        badgeContent={nuevas.length}
        sx={{
          '& .MuiBadge-badge': { borderRadius: '4px', fontSize: 14 },
        }}
        color="accent"
      >
        <IconBell
          sx={styles.iconContainer}
          onClick={() => {
            if (open) {
              closeNotificacionesDrawer();
            } else {
              openNotificacionesDrawer();
            }
          }}
        />
      </Badge>
    </Card>
  );
}
