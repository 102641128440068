import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { getDatosPrestamo, solicitarPrestamo } from 'services/Ares';
import { useTranslation } from 'react-i18next';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { useNavigate } from 'react-router-dom';
import useFirebase from 'hooks/useFirebase';
import useAlerts from 'hooks/useAlerts';
import useEmpleado from 'hooks/useEmpleado';
import TyCModal from 'components/TyCModal';
import Card from 'components/card/CardComponent';
import SolicitudPrestamoCard from './components/SolicitudPrestamoCard';
import LiquidacionDetallada from './components/LiquidacionDetallada';
import DatosEmpleadoCard from './components/DatosEmpleadoCard';
import solicitudPrestamoSchema from './schemas/solicitudPrestamo.schema';

const styles = {
  container: {
    display: 'flex',
    gap: {
      xs: '25px',
      md: '50px',
    },
    flexWrap: 'wrap',
  },
};

function crearSelectCuotas(maximo_cuotas) {
  return Array.from({ length: maximo_cuotas }, (_, i) => ({
    label: (i + 1).toString(),
    value: i + 1,
  }));
}

const initialState = {
  monto: '',
  cuotas: '',
  motivo: '',
  detalle_motivo: '',
  tipo_documento: '',
  nro_documento: '',
  apellido: '',
  nombre: '',
  domicilio: '',
  selects: {
    cuotas: [],
    motivos: [],
    tiposDocumentos: [],
  },
  datosEmpleado: {
    antiguedad: '',
    categoria: '',
    maximoCuotas: '',
    maximoOtorgar: '',
  },
  coeficientes: [],
  terminos: null,
};

export default function PedidoPrestamoPage() {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState(initialState);
  const isMobile = useMobileBreakpoint();
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const {
    state: { firebaseInstance },
  } = useFirebase();
  const { openSnackbar } = useAlerts();
  const navigate = useNavigate();
  const { state: { usuario } } = useEmpleado();

  const form = useFormik({
    initialValues,
    validationSchema:
      solicitudPrestamoSchema(usuario.nro_documento, initialValues.datosEmpleado.maximoOtorgar),
    enableReinitialize: true,
    onSubmit: async ({
      monto,
      cuotas,
      motivo,
      detalle_motivo,
      tipo_documento,
      nro_documento,
      apellido,
      nombre,
      domicilio,
    }) => {
      const res = await solicitarPrestamo({
        monto,
        cuotas,
        motivo,
        detalle_motivo,
        tipo_documento: tipo_documento || 0,
        nro_documento: nro_documento || 0,
        apellido,
        nombre,
        domicilio,
      });

      if (res.data.success) {
        firebaseInstance?.logEvent('solicito-prestamo');

        openSnackbar({
          state: true,
          message: res.data.message,
          severity: 'success',
        });
      } else {
        openSnackbar({
          state: true,
          message: res.data.message,
          severity: 'error',
        });
      }
    },
  });

  const handleVolver = () => {
    navigate('/solicitudes');
  };

  useEffect(() => {
    getDatosPrestamo().then((res) => {
      const {
        motivos,
        documentos,
        antiguedad,
        categoria,
        maximo_cuotas,
        maximo_otorgar,
        coeficientes,
        saldo_anterior,
        saldo_aem,
        terminos,
      } = res.data.data;
      setInitialValues((prevState) => ({
        ...prevState,
        tipo_documento: documentos.find((doc) => doc.label === 'DNI.').value,
        selects: {
          ...prevState.selects,
          cuotas: crearSelectCuotas(maximo_cuotas),
          motivos,
          tiposDocumentos: documentos,
        },
        datosEmpleado: {
          antiguedad,
          categoria,
          maximoCuotas: maximo_cuotas,
          maximoOtorgar: maximo_otorgar,
        },
        coeficientes,
        saldoAnterior: saldo_anterior,
        saldoAEM: saldo_aem,
        terminos,
      }));
    });
  }, []);

  return (
    <>
      {
      form.values.terminos && (
        <TyCModal terminos={form.values.terminos} handleVolver={handleVolver} />
      )
    }
      <Grid container padding={{ xs: 2, md: 4 }} spacing={{ xs: 2, md: 4 }}>
        {isMdScreen && (
        <Grid xs={12} item>
          <DatosEmpleadoCard datosEmpleado={form.values.datosEmpleado} />
        </Grid>
        )}
        <Grid xs={12} md={8} item>
          <SolicitudPrestamoCard form={form} />
        </Grid>
        <Grid xs={4} item>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            {!isMdScreen && (
            <Grid xs={12} item>
              <DatosEmpleadoCard datosEmpleado={form.values.datosEmpleado} />
            </Grid>
            )}
            {!isMobile && (
            <Grid xs={12} item>
              <Card sx={styles.container}>
                <LiquidacionDetallada form={form} />
              </Card>
            </Grid>
            )}
            <Grid xs={12} item>
              <Card>
                <Typography variant="body2">{t('pages.solicitudes.pedido_prestamo.aclaracion_aprobacion')}</Typography>
              </Card>
            </Grid>
            {/* <Grid xs={12} item>
            <Card>
              <Typography fontWeight={600}>Historial de solicitudes</Typography>
              <DataGridHistorial historial={[]} />
            </Card>
          </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
