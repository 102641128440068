import { Grid, Typography } from '@mui/material';
import { memo } from 'react';
import { IconTrash } from '@cooperativaobrera/react-icons/tabler-icons';
import useNotificaciones from 'hooks/useNotificaciones';
import { eliminarNotificacion } from 'services/NotificacionesService';
import IconoNotificacionesComponent from './IconoNotificacionesComponent';

function NotificacionComponent({ notificacion }) {
  const { handleModal, obtenerNotificaciones } = useNotificaciones();
  return (
    <Grid container>
      <Grid 
        xs={10} 
        item
        display="flex" 
        flexDirection="row"
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          handleModal({ open: 'verNotificacion', data: notificacion.id });
        }}
      >
        <Grid xs={1.5} item alignContent="center">
          <IconoNotificacionesComponent tipo={notificacion.tipo} />
        </Grid>
        <Grid item container>
          <Grid xs item>
            <Typography variant="caption" color="text.helper">
              {notificacion.fecha}
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ textWrap: 'auto' }}>
              {notificacion.titulo}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
        <IconTrash 
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            eliminarNotificacion(notificacion.id);
            obtenerNotificaciones();
          }} 
        />
      </Grid>
    </Grid>
  );
}

export default memo(NotificacionComponent);
