import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import AppRoutes from 'routes';
import { Provider as MobileAppbarContextProvider } from 'contexts/MobileAppbarContext';
import GlobalSnackbar from 'GlobalSnackbar';
import GlobalLoading from 'GlobalLoading';
import { Provider as AlertsContextProvider } from 'contexts/AlertsContext';
import { AuthAxiosInterceptor } from 'apis/AuthApi';
import { EmpleadosAxiosInterceptor } from 'apis/EmpleadosApi';
import { pdfjs } from 'react-pdf';
import NotificacionesDrawer from 'components/notificaciones/drawer';
import { Provider as AuthProvider } from './contexts/AuthContext';
import { Provider as EmpleadoProvider } from './contexts/EmpleadoContext';
import { Provider as SeguridadProvider } from './contexts/SeguridadContext';
import { Provider as FirebaseProvider } from './contexts/FirebaseContext';
import { Provider as ReciboProvider } from './pages/recibo/contexts/ReciboContext';
import { Provider as NotificacionesProvider } from './contexts/NotificacionesContext';
import MUITheme from './theme';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function App() {
  return (
    <AlertsContextProvider>
      <FirebaseProvider>
        <AuthProvider>
          <EmpleadoProvider>
            <ReciboProvider>
              <SeguridadProvider>
                <AuthAxiosInterceptor>
                  <EmpleadosAxiosInterceptor>
                    <ThemeProvider theme={MUITheme}>
                      <LocalizationProvider
                        adapterLocale={es}
                        utils={DateFnsUtils}
                        dateAdapter={AdapterDateFns}
                      >
                        <NotificacionesProvider>
                          <MobileAppbarContextProvider>
                            <CssBaseline />
                            <GlobalSnackbar />
                            <GlobalLoading />
                            <NotificacionesDrawer />
                            <AppRoutes />
                          </MobileAppbarContextProvider>
                        </NotificacionesProvider>
                      </LocalizationProvider>
                    </ThemeProvider>
                  </EmpleadosAxiosInterceptor>
                </AuthAxiosInterceptor>
              </SeguridadProvider>
            </ReciboProvider>
          </EmpleadoProvider>
        </AuthProvider>
      </FirebaseProvider>
    </AlertsContextProvider>
  );
}
