import { Grid } from '@mui/material';
import { useEffect } from 'react';
import useFirebase from 'hooks/useFirebase';
import SeleccionarReciboCard from './ReciboSueldoPDF/SeleccionarReciboSueldoCardComponent';
import ReciboSueldoPDF from './ReciboSueldoPDF/ReciboSueldoPDFComponent';
import useRecibo from '../hooks/useRecibo';

export default function RecibosComponent({ keyPrivadaEmpleado }) {
  const { state: { reciboSeleccionado }, obtenerRecibos } = useRecibo();
  const {
    state: { firebaseInstance },
  } = useFirebase();

  useEffect(() => {
    obtenerRecibos();
    firebaseInstance?.logEvent('visualizo-recibo-sueldo');
  }, []);

  return (
    <Grid container spacing={{ xs: 2, md: 4 }}>
      <Grid xs={12} lg={4} item>
        <SeleccionarReciboCard keyPrivadaEmpleado={keyPrivadaEmpleado} />
      </Grid>
      <Grid xs={12} lg={8} item>
        {reciboSeleccionado?.file && (
          <ReciboSueldoPDF pdf={reciboSeleccionado.file} />
        )}
      </Grid>
    </Grid>
  );
}
